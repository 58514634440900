var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("multiselect", {
    attrs: {
      label: "text",
      placeholder: _vm.$t("drivers.labels.selectPlate"),
      "track-by": "value",
      "close-on-select": true,
      disabled: !_vm.selectOptions.length || _vm.disabled,
      multiple: false,
      options: _vm.selectOptions,
      value: _vm.findSelectedItem,
    },
    on: { input: _vm.emitValue },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }