<template>
  <multiselect
    label="text"
    :placeholder="$t('drivers.labels.selectPlate')"
    track-by="value"
    :close-on-select="true"
    :disabled="!selectOptions.length || disabled"
    :multiple="false"
    :options="selectOptions"
    :value="findSelectedItem"
    @input="emitValue"
  />
</template>
<script>
import CARS_SCHEDULE_AVAILABLE_LIST from '@graphql/car/queries/fleet-list-schedule-available.gql';

export default {
  name: 'CarScheduleAvailableSelect',
  props: {
    value: {
      required: true,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    cars: [],
    loading: false,
  }),
  async beforeMount() {
    await this.fetchData();
  },
  computed: {
    findSelectedItem() {
      const selected = this.value;
      const available = this.selectOptions;

      return selected && available.length ? available.find(item => item.value === selected) : null;
    },
    selectOptions() {
      return this.cars.map(car => ({
        value: car.license_plate,
        text: `${car.license_plate} - ${car.brand?.name || ''} ${car.model?.name || ''}`,
      }));
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: CARS_SCHEDULE_AVAILABLE_LIST,
          variables: {
            filters: {
              where: {
                status: {
                  $not: ['RETIRED_RETURN', 'RETIRED_CRASH', 'RETIRED_THEFT', 'SEIZED', 'PRE_OPERATION', 'PREPARATION', 'OPS'],
                },
              },
              order: [],
            },
            page: 0,
          },
        });
        if (data.cars) {
          this.cars = data.cars.items;
        }
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    },
    emitValue({ value }) {
      this.$emit('input', value);
    },
  },
};
</script>
