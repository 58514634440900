<template>
  <b-modal id="deliveryAndDevolutionCarSchedule" lazy hide-footer size="lg" :title="modal.title" @hide="handleClose" @close="handleClose">
    <driver-header :id="$route.params.id" :reset-profile="false" />

    <b-row class="card-wrapper d-flex justify-content-end mt-3">
      <b-col md="12" class="mt-sm-3 mt-md-0">
        <b-form-group :label="$i18n.t('drivers.labels.scheduleType')">
          <multiselect
            label="text"
            :placeholder="$t('drivers.labels.select')"
            track-by="value"
            :close-on-select="true"
            :disabled="!modal.availableTypeSchedule.length || block_ui"
            :multiple="false"
            :options="modal.availableTypeSchedule"
            :value="getSelected"
            @input="setSelected"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title mr-2">{{ props.option.text }}</span>
                <span v-if="props.option.description" class="option__small" v-b-popover.hover.right="props.option.description"
                  ><i class="fa fa-info-circle"
                /></span>
              </div>
            </template>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col md="12" class="mt-sm-3 mt-md-0">
        <b-row class="d-flex justify-content-center mt-3">
          <b-col md="12" class="mt-sm-3 mt-md-0" :style="[isFieldVisible('fleetSelector') ? { display: 'block' } : { display: 'none' }]">
            <b-form-group :label="$i18n.t('drivers.labels.plate')">
              <car-schedule-available-select id="schedule-car" v-model="modal.metadata.fleet" :disabled="block_ui" />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-sm-3 mt-md-0">
            <b-form-group
              :label="$i18n.t('drivers.labels.carCategory')"
              :style="[isFieldVisible('fleetCategorySelector') ? { display: 'block' } : { display: 'none' }]"
            >
              <b-form-select
                id="fleet-category"
                class="mb-1"
                v-model="modal.metadata.fleetCategory"
                :options="fleetCategoryOptions"
                :disabled="block_ui"
                type="text"
                style="min-height: 40px"
              >
                <template #first>
                  <option :value="null" disabled>{{ $i18n.t('drivers.labels.selectCategory') }}</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-sm-3 mt-md-0">
            <b-form-group
              :label="$i18n.t('drivers.labels.carModel')"
              :style="[isFieldVisible('fleetModelSelector') ? { display: 'block' } : { display: 'none' }]"
            >
              <b-form-select
                id="fleet-model"
                class="mb-1"
                v-model="modal.metadata.fleetModel"
                :options="fleetModelOptions"
                :disabled="block_ui"
                type="text"
                style="min-height: 40px"
              >
                <template #first>
                  <option :value="null" disabled>{{ $i18n.t('drivers.labels.selectModel') }}</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="12" class="mt-sm-3 mt-md-0">
        <p>{{ $i18n.t('drivers.labels.reasonSchedule') }}</p>
        <b-form-textarea
          id="textAreaReasonChedule"
          v-model="modal.reasonText"
          rows="3"
          :placeholder="$i18n.t('drivers.labels.typeHere')"
          max-rows="6"
          :disabled="block_ui"
        />
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-3 card-wrapper">
      <b-col md="12">
        <h2>{{ $i18n.t('drivers.titles.devolution') }}</h2>
        <b-row class="d-flex justify-content-center mt-3">
          <b-form-group class="select-wrapper" style="width: 100%; padding: 0 15px" :label="'Condutor'">
            <b-form-select
              v-model="modal.selectedConductor"
              :options="modal.availableConductors"
              :disabled="!modal.availableConductors.length || block_ui"
              data-test="select-conductors"
            >
              <template slot="first">
                <option :value="null" disabled>
                  {{ $i18n.t('drivers.labels.select') }}
                </option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-row>

        <b-row class="d-flex justify-content-center mt-3">
          <b-col md="6" class="mt-sm-3 mt-md-0">
            <select-places
              :place-title="$i18n.t('drivers.labels.placesDevolution')"
              :place-text="$i18n.t('drivers.labels.selectPlaces')"
              :instance="'placeDevolution'"
              :type="{ devolution: true }"
              :disabled="block_ui"
              data-test="select-places"
              @eventPlaces="onEventPlaces($event)"
            />
          </b-col>

          <b-col md="6" class="mt-sm-3 mt-md-0" data-test="datepicker-devolution">
            <p>{{ $i18n.t('drivers.labels.dateDevolution') }}</p>
            <datepicker v-model="modal.devolutionSchedule_date" v-bind="devolutionDatePickerProps" :lang="getLanguage" :disabled="block_ui" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-3 card-wrapper">
      <b-col md="12">
        <h2>{{ $i18n.t('drivers.titles.delivery') }}</h2>
        <b-row class="d-flex justify-content-center mt-3">
          <b-col md="6" class="mt-sm-3 mt-md-0">
            <select-places
              :place-title="$i18n.t('drivers.labels.placesDelivery')"
              :instance="'placeDelivery'"
              :type="{ delivery: true }"
              :disabled="block_ui"
              @eventPlaces="onEventPlaces($event)"
            />
          </b-col>

          <b-col md="6" class="mt-sm-3 mt-md-0" data-test="datepicker-delivery">
            <p>{{ $i18n.t('drivers.labels.dateDelivery') }}</p>
            <datepicker v-model="modal.deliverySchedule_date" v-bind="deliveryDatePickerProps" :lang="getLanguage" :disabled="block_ui" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-end mt-3">
      <b-col md="12" class="text-right">
        <b-button :disabled="block_ui" variant="btn btn-secondary" style="margin-right: 50px" @click="handleClose">
          {{ $i18n.t('drivers.buttons.cancel') }}
        </b-button>

        <b-button data-test="btn-confirm-schedule" :disabled="!valid || block_ui" variant="success" @click="preConfirmSchedule">
          {{ $i18n.t('drivers.buttons.schedule') }}
        </b-button>
      </b-col>
    </b-row>
    <modal-pre-confirm-schedule :items="preConfirmItems" @returnPreConfirmSchedule="$_returnPreConfirmSchedule($event)" />
  </b-modal>
</template>

<script>
import DriverHeader from '@components/drivers/header';
import SelectPlaces from '@components/shared/SelectPlaces';
import { currentLanguage } from '@utils/language';
import { availableTypeDevolutionAndDeliverySchedule } from '@utils/availableTypeSchedule';
import { availableConductors } from '@utils/availableConductors';
import SchedulesDelivery from '@graphql/schedules/mutations/create-schedule-delivery.gql';
import SchedulesDevolution from '@graphql/schedules/mutations/create-schedule-devolution.gql';
import PreConfirmSchedule from '@graphql/schedules/queries/pre-confirm-schedule.gql';
import ModalPreConfirmSchedule from '@components/drivers/profile/modals/PreConfirmSchedule';
import CarScheduleAvailableSelect from '@components/select/car-schedule-available';
import { mapGetters } from 'vuex';
import { enumCarsCategory, enumCarsModel } from '@utils/car';

export default {
  name: 'DeliveryAndDevolutionCarSchedule',

  components: {
    DriverHeader,
    SelectPlaces,
    ModalPreConfirmSchedule,
    CarScheduleAvailableSelect,
  },

  data() {
    return {
      modal: {
        title: this.$i18n.t('drivers.titles.scheduleDevolutionAndDelivery'),
        selectedTypeSchedule: null,
        selectedConductor: null,
        devolutionSchedule_date: null,
        deliverySchedule_date: null,
        reasonText: null,
        placeDelivery: null,
        placeDevolution: null,
        availableTypeSchedule: availableTypeDevolutionAndDeliverySchedule,
        availableConductors: availableConductors,
        metadata: {
          fleetCategory: '',
          fleetModel: '',
          fleet: '',
        },
      },
      resultRequests: {
        delivery: null,
        devolution: null,
      },
      valid: false,
      block_ui: false,
      preConfirmItems: [],
      fleetCategoryOptions: [...enumCarsCategory],
      fleetModelOptions: [...enumCarsModel],
    };
  },

  computed: {
    ...mapGetters('env', {
      envSchedules: 'schedules',
    }),
    getLanguage() {
      return currentLanguage;
    },

    devolutionDatePickerProps() {
      return {
        type: 'datetime',
        timePickerOptions: {
          start: '06:30',
          step: '00:15',
          end: '20:30',
        },
        disabledDays(value) {
          const today = this.$moment(value).day();

          if (today === 0) {
            return today;
          }
        },
        shortcuts: false,
        clearable: false,
        editable: false,
        notBefore: this.today,
        notAfter: this.week,
        width: 'auto',
        format: 'DD/MM/YYYY HH:mm',
      };
    },

    deliveryDatePickerProps() {
      return {
        type: 'datetime',
        timePickerOptions: {
          start: '07:00',
          step: '00:15',
          end: '22:30',
        },
        shortcuts: false,
        clearable: false,
        editable: false,
        notBefore: this.today,
        notAfter: this.week,
        width: 'auto',
        format: 'DD/MM/YYYY HH:mm',
      };
    },

    today() {
      if (this.$moment().format('HH') > 18) {
        // If date > 18 send to another day
        return this.$moment().add(8, 'hours').format();
      }
      return this.$moment().format();
    },

    week() {
      return this.$moment().add(this.envSchedules.deliveryAndDevolution, 'weeks').format();
    },

    is_valid_schedule_date() {
      return (
        !!this.modal.devolutionSchedule_date &&
        !!this.modal.deliverySchedule_date &&
        this.$moment(this.modal.devolutionSchedule_date).isValid(true) &&
        this.$moment(this.modal.deliverySchedule_date).isValid(true)
      );
    },
    getSelected() {
      const selected = this.modal.selectedTypeSchedule;
      const available = this.modal.availableTypeSchedule;

      return selected && available.length ? available.find(item => item.value === selected) : null;
    },
  },

  watch: {
    modal: {
      deep: true,
      handler(value) {
        this.valid = false;
        const scheduleTypeValid = value.selectedTypeSchedule !== null && value.selectedTypeSchedule !== '';
        const scheduleDateValid = this.is_valid_schedule_date;
        const reasonTextValid = value.reasonText !== null && value.reasonText !== '';
        const placeDeliveryValid = value.placeDelivery !== null && value.placeDelivery !== '';
        const placeDevolutionValid = value.placeDevolution !== null && value.placeDevolution !== '';
        const selectedConductorValid = value.selectedConductor !== null && value.selectedConductor !== '';
        const fleetSelect = this.modal.selectedTypeSchedule === 'EXCHANGE_MAINTENANCE_ORIGINAL_CAR' ? value.metadata.fleet !== '' : true;
        const fleetCategorySelect = this.modal.selectedTypeSchedule !== 'EXCHANGE_MAINTENANCE_ORIGINAL_CAR' ? value.metadata.fleetCategory !== '' : true;
        const fleetModelSelect = this.modal.selectedTypeSchedule !== 'EXCHANGE_MAINTENANCE_ORIGINAL_CAR' ? value.metadata.fleetModel !== '' : true;

        this.valid = [
          scheduleTypeValid,
          scheduleDateValid,
          reasonTextValid,
          placeDeliveryValid,
          selectedConductorValid,
          placeDevolutionValid,
          fleetSelect,
          fleetCategorySelect,
          fleetModelSelect,
        ].every(valid => valid);
      },
    },

    resultRequests: {
      deep: true,
      handler(value) {
        if (value.devolution && value.delivery) {
          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalWaitingDelivery.texts.deliveryWasSchedule'),
            timer: 2000,
            text: '',
            showConfirmButton: false,
            showCancelButton: false,
            onAfterClose: async () => {
              await this.handleClose();
              await window.location.reload();
            },
          };

          this.$swal(configSwalSuccess);

          this.block_ui = false;
        } else if (!value.devolution && !value.delivery) {
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalWaitingDelivery.texts.couldNotPossibleScheduleThisDelivery'),
            text: 'error',
          };

          this.$swal(configSwalFail);

          this.block_ui = false;
        }
      },
    },
  },

  methods: {
    isFieldVisible(fieldName) {
      const fields = {
        fleetSelector: false,
        fleetCategorySelector: false,
        fleetModelSelector: false,
      };

      if (this.modal.selectedTypeSchedule) {
        if (this.modal.selectedTypeSchedule === 'EXCHANGE_MAINTENANCE_ORIGINAL_CAR') {
          fields.fleetSelector = true;
          fields.fleetCategorySelector = false;
          fields.fleetModelSelector = false;
        } else {
          fields.fleetSelector = false;
          fields.fleetCategorySelector = true;
          fields.fleetModelSelector = true;
        }
      }

      return fields[fieldName] ?? false;
    },
    setSelected({ value }) {
      this.modal.selectedTypeSchedule = value;
      this.modal.metadata = {
        fleetCategory: '',
        fleetModel: '',
        fleet: '',
      };
    },
    handleClose() {
      this.modal.selectedTypeSchedule = null;
      this.modal.devolutionSchedule_date = null;
      this.modal.deliverySchedule_date = null;
      this.modal.reasonText = null;
      this.modal.placeDelivery = null;
      this.modal.placeDevolution = null;
      this.modal.selectedConductor = null;
      this.modal.metadata = {
        fleetCategory: '',
        fleetModel: '',
        fleet: '',
      };

      this.$root.$emit('bv::hide::modal', 'deliveryAndDevolutionCarSchedule');
    },

    async preConfirmSchedule() {
      this.block_ui = true;

      await this.$apollo
        .query({
          query: PreConfirmSchedule,
          variables: {
            filters: {
              where: {
                driver: this.$route.params.id,
                status: 'SCHEDULED',
                kind: ['DELIVERY', 'DEVOLUTION'],
              },
              order: [['created_at', 'ASC']],
            },
          },
        })
        .then(res => {
          const schedules = res.data.schedules;

          if (schedules && schedules.items && schedules.total > 0) {
            let list = schedules.items.map(item => {
              return {
                type: item.kind,
                reason: item.type,
                date: item.scheduled_at,
                place: item.place.name,
                scheduledBy: item.assigned_by,
                comments: item.comments,
              };
            });

            list.sort((a, b) => {
              let typeA = a.type.toLowerCase();
              let typeB = b.type.toLowerCase();

              if (typeA < typeB) {
                return -1;
              } else if (typeA > typeB) {
                return 1;
              } else {
                return 0;
              }
            });

            this.preConfirmItems = list;

            this.$root.$emit('bv::show::modal', 'preConfirmSchedule');
          } else {
            this.confirmSchedule();
          }
        })
        .catch(err => {
          this.$swal({
            type: 'error',
            title: `Ocorreu um erro na sua solicitação, tente novamente`,
            text: err,
          });
        });
    },

    $_returnPreConfirmSchedule(event) {
      this.$root.$emit('bv::hide::modal', 'preConfirmSchedule');

      if (event === 'cancel') this.block_ui = false;

      if (event === 'confirmed') this.confirmSchedule();
    },

    confirmSchedule() {
      this.block_ui = true;

      this.$apollo
        .mutate({
          mutation: SchedulesDelivery,
          variables: {
            input: {
              driver: this.$route.params.id,
              type: this.modal.selectedTypeSchedule,
              place: this.modal.placeDelivery,
              comments: this.modal.reasonText,
              scheduled_at: this.modal.deliverySchedule_date,
              forceSchedule: true,
              metadata: this.modal.metadata,
            },
          },
        })
        .then(result => {
          if (result.data.createScheduleDelivery.id) {
            this.resultRequests.delivery = true;
          }
        })
        .catch(error => {
          this.resultRequests.delivery = false;
        });

      this.$apollo
        .mutate({
          mutation: SchedulesDevolution,
          variables: {
            input: {
              driver: this.$route.params.id,
              type: this.modal.selectedTypeSchedule,
              place: this.modal.placeDevolution,
              comments: this.modal.reasonText,
              scheduled_at: this.modal.devolutionSchedule_date,
              metadata: {
                conductor: this.modal.selectedConductor,
                ...this.modal.metadata,
              },
              forceSchedule: true,
            },
          },
        })
        .then(result => {
          if (result.data.createScheduleDevolution.id) {
            this.resultRequests.devolution = true;
          }
        })
        .catch(error => {
          this.resultRequests.devolution = false;
        });
    },

    onEventPlaces($event) {
      this.modal[$event.instance] = $event.value;
    },
  },
};
</script>

<style scoped>
.card-wrapper {
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;
  margin: 0px;
  padding: 15px 0;
}

button {
  min-width: 135px;
  margin-bottom: 50px;
}
</style>
